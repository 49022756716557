<template>
  <div>
    <button
      id="leagues-btn"
      class="btn btn-secondary dropdown-toggle ddb dropdown-toggle dropdown-filter"
      type="button"
      data-toggle="modal"
      data-target="#leaguesModal"
    >
      {{ selectedLeague }}
    </button>
    <div
      class="modal fade"
      id="leaguesModal"
      tabindex="-1"
      aria-labelledby="leaguesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-dark" id="exampleModalLabel">
              Leagues
            </h5>
            <span type="button" class="close" data-dismiss="modal"
              >&times;</span
            >
          </div>
          <div
            style="font-size: medium; text-align: center"
            class="row modal-body text-center"
          >
            <div
              style="
                margin: 1px;
                background-color: var(--light-red);
                color: var(--darkBackground);
                width: auto;
                border: solid var(--red) 1px;
              "
              class="btn btn-sm col text-center"
            >
              <a @click="setLeague(-1, 'All leagues')">
                <div class="item-wrapper text-dark">Filter by league</div>
              </a>
            </div>

            <div
              style="
                margin: 1px;
                background-color: var(--light-red);
                color: var(--darkBackground);
                width: auto;
                border: solid var(--red) 1px;
              "
              class="btn btn-sm col text-center"
              v-for="s in leagues"
              :key="getLeagueKey(s.competition_id)"
            >
              <a @click="setLeague(s)">
                <div class="item-wrapper">
                  <span
                    class="text-dark small-text"
                    style="font-weight: 600"
                    v-text="s.country_name"
                  ></span
                  ><br />
                  <span
                    class="text-dark item-wrapper-span"
                    v-text="s.competition"
                  ></span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "leagues",
  mounted() {
    // this.getMarketLeagues(1);
    this.$store.dispatch("getLeague");
    //this.getLeagues(this.spo);
  },
  computed: {
    // sports: function () {
    //   return this.$store.state.sports;
    // },

    leagues: function () {
      if (
        this.$store.state.leagues !== undefined &&
        this.$store.state.leagues !== null
      ) {
        // console.log(`LIG-->` + JSON.stringify(this.$store.state.leagues));

        return this.$store.state.leagues;
      }

      return this.$store.state.leagues;
    },

    // markets: function () {
    //   return this.$store.state.markets;
    // },
  },
  methods: {
    // getLeagues: function (sport_id) {
    //   this.$store.dispatch("getLeague", sport_id);
    // },
    getLeagueKey: function (index) {
      return Math.random()
        .toString(20)
        .replace("0.", "random-id-" + index + "-");
    },
    // setLeagueSport: function (sport) {
    //   this.sport = sport;
    //   this.sport_name = sport.sport_name;
    //   this.getMarketLeagues(sport.sport_id);
    //   this.$store.dispatch("setLeagueSportID", sport.sport_id);
    //   this.$store.dispatch("setLeagueSportName", sport.sport_name);
    //   this.$store.dispatch("setMarketID", 0);
    //   this.$store.dispatch("setCompetitionID", 0);
    //   this.$store.dispatch("setCategoryID", 0);
    // },

    setLeague: function (league, leagueName = null) {
      document.getElementById("leagues-btn").click();

      if (league === -1) {
        this.selectedLeague = leagueName || "Leagues";
        this.$store.dispatch("setCompetitionID", 0);
        return;
      }

      this.competition_name = league.country_name + " - " + league.competition;
      this.league = league;
      this.competition_name = league.competition;
      this.selectedLeague =
        league.competition.length > 11
          ? league.competition.substring(0, 11)
          : league.competition;
      this.$store.dispatch("setCompetitionID", league.competition_id);
    },
    // getMarketLeagues: function (sport_id) {
    //   this.$store.dispatch("getMarketLeagues", sport_id);
    // },
    // setMarket: function (market) {
    //   this.market = market;
    //   this.market_name = market.market_name;
    //   this.$store.dispatch("setOutcomes", market.outcomes);
    //   this.$store.dispatch("setMarketID", market.market_id);
    // },
    // setHour: function (h) {
    //   this.selected_hour = h;
    //   //this.EventBus.$emit('filter:hour',h);
    //   this.$store.dispatch("setHour", h);
    // },
    // humanHourName: function (h) {
    //   if (h === -1) {
    //     return "All Time";
    //   }

    //   return h === "" || h === "" || h === 0 ? "Time" : h + " Hours";
    // },
  },
  data: function () {
    return {
      sport: {},
      league: {},
      market: {},
      competition_name: "Top League",
      sport_name: "Sports",
      market_name: this.$t("market"),
      selected_hour: 0,
      hours: [-1, 1, 3, 6, 12, 24, 48],
      selectedLeague: this.$t("filterByLeague"),
    };
  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}
</style>
